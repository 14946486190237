<template>
  <main>
    <div v-if="buildings" class="subnav-detail">
      <div v-if="user">
        <h4>{{ user.displayName }}'s Dashboard</h4>
      </div>
      <div class="row">
        <div class="col">
          <router-link
            :to="{
              name: 'Buildings'
            }"
            ><h5>Buildings</h5></router-link
          >
          <div v-for="building in buildings" :key="building.name">
            <router-link
              :to="{
                name: 'BuildingDetails',
                params: { buildingId: building.id }
              }"
            >
              <div class="single">
                <div class="col">
                  <div class="building-thumb">
                    <img :src="building.image1URLThumb" />
                  </div>
                </div>
                <div class="col">
                  <div class="name">
                    {{ building.name }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'Tenants'
            }"
            ><h5>Tenants</h5></router-link
          >
          <p>list of tenants here...</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";

export default {
  name: "Home",
  setup() {
    const { user } = getUser();
    const { error, documents: buildings } = getCollection(
      "buildings",
      [["managerId", "==", user.value.uid]],
      [["name", "asc"]]
    );

    return { user, error, buildings };
  }
};
</script>
